.register {
  background: $color-cf-black;
  position: relative;

  &--large {
    @include media-breakpoint-up(xl) {
      @include blue-gradient-vertical-reverse;
    }
  }

  &--with-confetti::before {
    @include media-breakpoint-up(xl) {
      @include confetti-decoration;
      background-size: contain;
      background-image: url('../../assets/images/confetti-register.png');
      height: 500px;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    gap: 24px;
    padding: 30px 0;
    position: relative;

    @include media-breakpoint-up(md) {
      gap: 40px;
    }
    @include media-breakpoint-up(xl) {
      align-items: center;
      flex-direction: row;
      gap: 50px;
      height: calc(var(--vh, 1vh) * 100);
    }
  }

  &--large &__container {
    @include media-breakpoint-up(xl) {
      gap: 172px;
    }
  }

  &--with-header &__container {
    @include media-breakpoint-up(xl) {
      padding-left: 150px;
    }

    &:after {
      bottom: 18px;
      @include media-breakpoint-up(xl) {
        background: $color-white;
        content: '';
        height: 1px;
        left: 0;
        position: absolute;
        right: 0;
        width: 200vw;
      }

      @media (min-height: 670px) {
        bottom: 54px;
      }

      @media (min-height: 840px) {
        bottom: 118px;
      }
    }
  }

  &__header {
    display: none;
    @include media-breakpoint-up(xl) {
      @include font-stack-gilroy-medium;
      border-bottom: 1px solid $color-white;
      color: rgba($color-white, 0.4);
      display: block;
      font-size: 10px;
      left: 28px;
      letter-spacing: 10px;
      line-height: 17px;
      padding-bottom: 10px;
      position: absolute;
      text-transform: uppercase;
      top: 175px;
      transform: rotate(90deg);
      transform-origin: top left;
      width: calc((var(--vh, 1vh) * 100) - 175px);
    }

    &:before {
      @include media-breakpoint-up(xl) {
        border-style: solid;
        border-width: 3px 0 3px 6px;
        border-color: transparent transparent transparent $color-white;
        bottom: -3px;
        content: '';
        height: 0;
        left: 0;
        position: absolute;
        width: 0;
      }
    }
  }

  &__col-1 {
    @include media-breakpoint-up(xl) {
      flex-basis: 400px;
    }
  }

  &--large &__col-1 {
    @include media-breakpoint-up(xl) {
      flex-basis: 520px;
    }
  }

  &__col-2 {
    @include media-breakpoint-up(xl) {
      flex-basis: 380px;
    }
  }

  &__title {
    @include title;
    margin-bottom: 24px;
    text-align: left;

    > span {
      display: block;
    }
  }

  &--large &__title {
    text-align: center;
    @include media-breakpoint-up(xl) {
      text-align: left;
    }
  }

  &__description {
    > p {
      @include font-stack-montserrat;
      color: $color-white;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.3;
      margin-bottom: 20px;
      @include media-breakpoint-up(xl) {
        font-size: 24px;
      }
      @include media-breakpoint-xl-small() {
        font-size: 20px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__sponsor {
    margin-bottom: 24px;
  }

  &__sponsor-title {
    @include font-stack-montserrat;
    color: $color-white;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.2;
    margin-bottom: 4px;
  }

  &__sponsor-logo {
    color: $color-white;
    width: 140px;
    @include media-breakpoint-up(md) {
      width: 216px;
    }
  }

  &__metadata {
    margin-bottom: 24px;
  }

  &__metadata-list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__metadata-list-item {
    @include font-stack-montserrat;
    border-bottom: 1px solid $color-white;
    color: $color-white;
    font-size: 12px;
    line-height: 19px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    padding-left: 32px;
    padding-right: 12px;
    position: relative;
    @include media-breakpoint-up(md) {
      font-size: 14px;
      margin-bottom: 16px;
      padding-bottom: 16px;
      padding-right: 0;
    }

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  }

  &__metadata-icon {
    color: $color-white;
    left: 3px;
    position: absolute;
    top: 0;
  }

  &__cta {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
    @include media-breakpoint-up(xl) {
      width: 83%;
    }
  }

  &__info-nav {
    margin-top: 60px;
    @include media-breakpoint-up(xl) {
      left: 0;
      position: absolute;
      right: 0;
    }
  }
  &__info-nav.top-large {
    @include media-breakpoint-up(xl) {
      margin-top: 120px;
      left: 150px;
    }
  }
}
