.lessons-list {
  color: $color-white;
  @include media-breakpoint-up(md) {
    margin-top: 26px;
  }
  @include media-breakpoint-up(xl) {
    margin-left: 150px;
    margin-top: 0;
  }

  &__container {
    display: grid;
    grid-auto-columns: 1fr;
    row-gap: 53px;
    margin-top: 40px;
    padding: 0;
    list-style: none;
    @include media-breakpoint-up(xl) {
      max-width: 700px;
      padding-right: 75px;
    }
  }

  &__line {
    display: none;
    @include media-breakpoint-up(xl) {
      display: block;
      margin-left: -200px;
    }
  }

  &__title {
    @include font-stack-gilroy-light;
    font-size: 32px;
    font-weight: 400;
    letter-spacing: 0.422px;
    text-align: center;
    line-height: 34px;
    max-width: 200px;
    margin: auto auto 50px;
    @include media-breakpoint-up(md) {
      font-size: 64px;
      max-width: none;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: auto;
      line-height: normal;
      text-align: left;
    }

    span {
      color: $color-light-green;
    }
  }
}
