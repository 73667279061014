.lessons-grid {
  position: relative;
  @include media-breakpoint-up(xl) {
    width: auto;
  }

  &:before {
    background: $color-cf-black;
    content: '';
    height: 400px;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: $zi-below-ground;
    @include media-breakpoint-up(md) {
      height: 200px;
    }
    @include media-breakpoint-up(xl) {
      bottom: 0;
      height: calc(var(--vh, 1vh) * 100);
      right: auto;
      width: 717px;
    }
  }

  &:after {
    @include media-breakpoint-up(xl) {
      background: radial-gradient(
        49.43% 81.68% at 0% 100%,
        rgba(103, 237, 255, 0.15) 0%,
        rgba(103, 246, 255, 0) 100%
      );
      filter: blur(30px);
      content: '';
      position: absolute;
      z-index: $zi-below-ground;
      bottom: 0;
      height: 735px;
      width: 651px;
      left: 717px;
    }
  }

  > .container {
    @include media-breakpoint-up(xl) {
      max-width: none;
      padding: 0;
    }
    &::after {
      @include media-breakpoint-up(xl) {
        background: radial-gradient(
          53.65% 92.65% at 107.91% -7.39%,
          rgba(103, 237, 255, 0.15) 0%,
          rgba(103, 246, 255, 0) 100%
        );
        filter: blur(30px);
        content: '';
        position: absolute;
        z-index: $zi-below-ground;
        top: 0;
        right: 0;
        width: 704px;
        height: 795px;
      }
    }
  }

  > .container > .row {
    @include media-breakpoint-up(xl) {
      margin: 0;
    }
  }

  > .container > .row > .col {
    @include media-breakpoint-up(xl) {
      padding: 0;
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    position: relative;
    @include media-breakpoint-up(md) {
      padding-left: 88px;
    }
    @include media-breakpoint-up(xl) {
      align-items: center;
      flex-direction: row;
      gap: 80px;
      height: calc(var(--vh, 1vh) * 100);
      padding-left: 0;
    }
  }

  &__title {
    @include title-large;
    margin-bottom: 53px;
    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
      margin-top: -50px;
      text-align: left;
      width: 432px;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 48px;
    list-style: none;
    margin: 0;
    padding: 0;
    position: relative;
    z-index: $zi-lowest;
    @include media-breakpoint-up(md) {
      gap: 92px;
    }
    @include media-breakpoint-up(xl) {
      flex-direction: row;
      gap: 120px;
      width: 2820px;
      width: 2165px;
    }
  }

  &__list-item {
    @include media-breakpoint-up(xl) {
      flex-basis: 860px;
      flex-shrink: 0;
    }
  }

  &__timeline {
    display: none;
    @include media-breakpoint-up(md) {
      display: flex;
      gap: 160px;
      left: 48px;
      position: absolute;
      top: 80px;
      transform: rotate(90deg);
      transform-origin: top left;
    }
    @include media-breakpoint-up(xl) {
      bottom: 40px;
      justify-content: space-between;
      left: 757px;
      right: 40px;
      top: auto;
      transform: none;
      transform-origin: center;
    }
    @media (min-width: 1200px) and (max-height: 800px) {
      bottom: 20px;
    }
  }

  &__timeline-item {
    @include font-stack-gilroy-bold;
    align-items: center;
    color: $color-grey;
    display: flex;
    font-size: 48px;
    gap: 16px;
    line-height: 1;
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    @include media-breakpoint-up(xl) {
      color: rgba($color-grey, 0.4);
      font-size: 116px;
    }
    @media (min-width: 1200px) and (max-height: 800px) {
      font-size: 58px;
    }
  }

  &__timeline-item-line {
    width: 114px;
    @include media-breakpoint-up(xl) {
      bottom: 0;
      position: absolute;
      right: 0;
      width: 250px;
    }
  }
}
