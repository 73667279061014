body {
  @include font-stack-montserrat;

  * {
    @include media-breakpoint-down(sm) {
      --bs-gutter-x: 3rem !important;
    }
  }
}

a {
  color: inherit;
  text-decoration: none;
  transition: color .4s ease, background-color .4s ease;

  &:active,
  &:hover {
    outline: none;
  }
}

strong {
  font-weight: bold;
}
