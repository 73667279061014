.podcast {
  &__iframe {
    max-width: 100%;
    margin: 0 auto;
    display: flex;
    @include media-breakpoint-up(xl) {
      margin: 0;
    }
  }
}
