.video-content {
  background-color: $color-white;
  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: flex-end;
    padding-bottom: 95px;
    width: 150vw;
  }
  @include media-breakpoint-up(xxl) {
    width: 100vw;
  }
  @include media-breakpoint-xl-small() {
    align-items: center;
    padding-bottom: 0;
  }

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    @include media-breakpoint-up(xl) {
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
    }
  }

  &__description {
    margin-top: -20%;
    @include media-breakpoint-up(xl) {
      margin-top: 0;
      display: flex;
    }
    @include media-breakpoint-xl-small() {
      align-items: center;
    }
  }

  &__card {
    text-align: center;
    display: flex;
    flex-direction: column;
    max-width: 475px;
    margin: 0 auto;
    gap: 16px;
    @include media-breakpoint-up(xl) {
      text-align: left;
      max-width: 417px;
      margin: 0 300px 0 0;
    }
  }

  &__title {
    @include font-stack-gilroy;
    color: $color-navy;
    font-size: 32px;
    letter-spacing: 0.422px;
    font-weight: 300;
    line-height: normal;
    @include media-breakpoint-up(md) {
      font-size: 64px;
    }
    @include media-breakpoint-xl-small() {
      font-size: 40px;
    }

    span {
      font-weight: 400;
    }
  }

  &__text {
    color: $color-dark-blue;
    font-weight: 300;
    font-size: 14px;
    padding: 0 30px;
    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
    @include media-breakpoint-up(xl) {
      padding-right: 55px;
      padding-left: 0;
    }
    @include media-breakpoint-xl-small() {
      line-height: 1.2;
    }

    a {
      text-decoration: underline;
    }
  }

  &__logo {
    color: $color-blue;

    svg {
      width: 150px;
      @include media-breakpoint-xl-small() {
        width: 120px;
      }
    }
  }

  &__image-right {
    display: flex;
    margin: 40px auto 0;
    z-index: $zi-lowest;
    width: 100%;
    max-width: 500px;
    max-height: 100vh;
    min-height: 420px;
    object-fit: cover;
    position: relative;
    @include media-breakpoint-up(xl) {
      max-width: 100%;
      width: auto;
      margin: 0;
      display: block;
      position: absolute;
      right: 0;
      transform: translateX(50%);
      bottom: -95px;
    }
  }

  &--elevating &__image-right {
    object-position: calc(50% + 40px) 50%;
    max-width: 100%;
    object-fit: contain;
    max-height: 396px;
    min-height: auto;

    @include media-breakpoint-up(sm) {
      max-height: 775px;
    }

    @include media-breakpoint-up(xl) {
      object-position: 50% 50%;
      object-fit: cover;
      max-height: 100vh;
      max-width: 769px;
    }
  }

  &--claiming &__image-right {
    max-height: 375px;
    object-fit: contain;
    object-position: calc(50% - 40px) 50%;
    max-width: 100%;
    min-height: auto;

    @include media-breakpoint-up(sm) {
      max-height: 775px;
      object-position: calc(50% - 107px) 50%;
    }

    @include media-breakpoint-up(xl) {
      object-position: 50% 50%;
      object-fit: cover;
      max-height: 100vh;
      max-width: 520px;
    }
  }

  &--left-image-sm &__image-right {
    @include media-breakpoint-up(xl) {
      right: 40px;
    }
  }

  &__video-card {
    flex-shrink: 0;
    width: calc(100% - 11%);
    margin: 0 5.5% 0;
    transform: translateY(-50%);
    @include media-breakpoint-up(xl) {
      transform: none;
      margin: 0 0 0 -300px;
      width: 1240px;
    }
    @include media-breakpoint-up(xxl) {
      margin: 0 0 0 -620px;
    }
    @include media-breakpoint-xl-small() {
      margin: 0 0 0 -500px;
      width: 1000px;
    }
  }

  &__line {
    display: none;
    @include media-breakpoint-up(xl) {
      display: block;
      position: absolute;
      // 717px = width of right container.
      // 37px = space between right container and end of line.
      width: calc(100% - 717px - 37px);
      top: 50%;
    }
  }
}
