.hero {
  @include blue-gradient-responsive;
  position: relative;

  &::before {
    @include confetti-decoration;
    background-image: url('../../assets/images/confetti-mobile.png');
    height: 60px;
    @include media-breakpoint-up(sm) {
      background-size: contain;
      height: 166px;
      background-image: url('../../assets/images/confetti-tablet.png');
    }
    @include media-breakpoint-up(md) {
      height: 500px;
      background-image: url('../../assets/images/confetti.png');
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    padding-bottom: 30px;
    position: relative;
    @include media-breakpoint-up(md) {
      padding-top: 174px;
    }
    @include media-breakpoint-up(xl) {
      justify-content: center;
      height: calc(var(--vh, 1vh) * 100);
      padding-top: 20px;
      padding-bottom: 14px;
    }
  }

  &__content {
    padding-left: 45px;
    position: relative;
    @include media-breakpoint-up(md) {
      padding-left: 76px;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 112px;
    }
  }

  &__branding {
    border-bottom: 1px solid $color-white;
    left: 0;
    padding-bottom: 6px;
    position: absolute;
    top: 100%;
    transform: rotate(-90deg);
    transform-origin: top left;
    width: 100vmax;

    @include media-breakpoint-up(md) {
      padding-bottom: 10px;
    }
    @include media-breakpoint-up(xl) {
      padding-bottom: 22px;
    }

    &:before {
      border-style: solid;
      border-width: 3px 0 3px 6px;
      border-color: transparent transparent transparent $color-white;
      bottom: -3px;
      content: '';
      height: 0;
      left: 0;
      position: absolute;
      width: 0;
    }
  }

  &__branding-link {
  }

  &__logo {
    color: $color-white;
    width: 78px;
    @include media-breakpoint-up(md) {
      width: 208px;
    }
    @include media-breakpoint-up(xl) {
      width: 300px;
    }
  }

  &__cta {
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
      position: absolute;
      right: 84px;
      top: 24px;
      z-index: $zi-medium;
    }
  }

  &__title {
    @include font-stack-gilroy-light;
    color: $color-white;
    font-size: 32px;
    letter-spacing: 0.422px;
    line-height: 34px;
    margin-bottom: 8px;
    @include media-breakpoint-up(md) {
      font-size: 64px;
      line-height: 73px;
      margin-bottom: 18px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 84px;
      line-height: 108px;
      margin-bottom: 20px;
    }
    @include media-breakpoint-xl-small() {
      font-size: 76px;
      line-height: 90px;
    }

    > span {
      @include font-stack-gilroy-bold;
      @include yellow-gradient;
      background-clip: text;
      display: block;
      font-size: 48px;
      letter-spacing: 0.422px;
      line-height: 44px;
      text-fill-color: transparent;
      text-transform: uppercase;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      @include media-breakpoint-up(md) {
        font-size: 96px;
        line-height: 100px;
        margin-bottom: 18px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 116px;
        line-height: 112px;
        margin-bottom: 0;
      }
      @include media-breakpoint-xl-small() {
        font-size: 90px;
      }
    }
  }

  &__description {
    @include media-breakpoint-up(xl) {
      max-width: 655px;
    }

    > p {
      @include font-stack-montserrat;
      color: $color-white;
      font-size: 16px;
      font-weight: 300;
      line-height: 1.3;
      margin-bottom: 20px;
      @include media-breakpoint-up(xl) {
        font-size: 24px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__copy {
    display: none;
    @include media-breakpoint-up(xl) {
      @include font-stack-montserrat;
      bottom: 14px;
      color: $color-white;
      display: block;
      font-size: 12px;
      font-style: italic;
      left: 0;
      line-height: 1.2;
      position: absolute;
    }
  }
}
