.video-card {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  border-radius: 40px;
  overflow: hidden;
  z-index: $zi-lowest;
  @include media-breakpoint-up(md) {
    flex-direction: row;
  }
  @include media-breakpoint-up(lg) {
    flex-direction: column;
  }

  &__media {
    height: 0;
    overflow: hidden;
    padding-bottom: calc((9 / 16) * 100%);
    position: relative;
    transition: border-color 0.4s ease;
    width: 100%;
    @include media-breakpoint-up(lg) {
      flex-basis: 100%;
      height: auto;
      padding-bottom: calc((9 / 16) * 100%);
    }

    &::after {
      content: '';
      position: absolute;
      inset: 0;
      margin: 3px;
      background-color: $black;
      border-radius: 40px;
      z-index: $zi-below-ground;
    }

    &-hide {
      img,
      .video-card__icon {
        opacity: 0;
        transition: opacity 300ms linear;
      }
    }

    svg {
      max-width: 76px;
      @include media-breakpoint-up(lg) {
        max-width: none;
      }
    }
  }

  &--focused &__media {
    @include media-breakpoint-up(lg) {
      border-color: $color-light-blue;
    }
  }

  &__image {
    height: 100%;
    object-fit: cover;
    object-position: center;
    position: absolute;
    width: 100%;
    border-radius: 40px;
  }

  &__icon {
    color: $color-aqua;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    width: 112px;
    height: 112px;
  }

  &__iframe {
    position: absolute;
    inset: 0;
    height: 100%;
    width: 100%;
  }
}
