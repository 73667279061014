@mixin font-stack-montserrat {
  font-family: 'Montserrat', sans-serif;
}

@mixin font-stack-gilroy {
  font-family: 'Gilroy', sans-serif;
}

@mixin font-stack-gilroy-light {
  font-family: 'Gilroy', sans-serif;
  font-weight: 300;
}

@mixin font-stack-gilroy-medium {
  font-family: 'Gilroy', sans-serif;
  font-weight: 500;
}

@mixin font-stack-gilroy-bold {
  font-family: 'Gilroy', sans-serif;
  font-weight: 700;
}

@mixin blue-gradient-horizontal {
  background: linear-gradient(164deg, #122947 0%, #3191B7 100%);
}

@mixin blue-gradient-vertical {
  background: linear-gradient(360deg, $color-cf-black 0%, $color-special-blue 100%);
}

@mixin blue-gradient-vertical-reverse {
  background: linear-gradient(to right, $color-cf-black , $color-special-blue);
}

@mixin blue-gradient-responsive {
  @include blue-gradient-vertical;
  @include media-breakpoint-up(xl) {
    @include blue-gradient-horizontal;
  }
}

@mixin yellow-gradient {
  background: linear-gradient(90.74deg, #f4e36b 18.21%, #fffefd 91.91%),
    linear-gradient(135.32deg, #f4e36b 24.86%, #fffefd 78.38%);
}

@mixin confetti-decoration {
  content: '';
  position: absolute;
  width: 100%;
  top: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

@mixin title {
  @include font-stack-gilroy-light;
  color: $color-white;
  font-size: 32px;
  letter-spacing: 0.422px;
  line-height: 34px;
  text-align: center;
  @include media-breakpoint-up(md) {
    font-size: 64px;
    line-height: 68px;
  }

  > span {
    color: $color-aqua;
  }
}

@mixin title-large {
  @include font-stack-gilroy-light;
  color: $color-white;
  font-size: 32px;
  letter-spacing: 0.422px;
  line-height: 34px;
  text-align: center;
  @include media-breakpoint-up(md) {
    font-size: 64px;
    line-height: 68px;
  }
  @include media-breakpoint-up(xl) {
    font-size: 96px;
    line-height: 96px;
  }

  > span {
    color: $color-aqua;
  }
}

@mixin media-breakpoint-xl-small {
  @media (min-width: 1200px) and (max-height: 800px) {
    @content;
  }
}

