.button {
  @include font-stack-montserrat;
  background: none;
  border: 1px solid transparent;
  border-radius: 160px;
  color: $color-dark-blue;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 600;
  justify-content: center;
  line-height: 22px;
  margin: 0;
  padding: 17px;
  text-align: center;
  text-transform: uppercase;
  transition: transform 0.2s ease-in-out, background-color 0.3s ease, border-color 0.4s ease;
  overflow: hidden;
  position: relative;
  text-decoration: none;
  will-change: transform;
  z-index: 0;
  @include media-breakpoint-up(md) {
    min-width: 186px;
  }

  &::after {
    content: '';
    background-color: $color-cf-black;
    border-radius: 160px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(10deg);
    transform-origin: top left;
    transition: transform 0.2s ease-out;
    will-change: transform;
    z-index: -1;
  }

  &:hover,
  &:active {
    will-change: transform;
    &::after {
      transform: translate(0, 0);
    }
  }

  &[disabled] {
    cursor: not-allowed;

    &::after {
      content: none;
    }
  }

  &--with-icon {
    @include media-breakpoint-up(md) {
      justify-content: space-between;
    }
  }

  &__icon-spinner {
    color: $color-dark-blue;
    margin-right: 13px;
  }

  &__icon-arrow-up-right {
    color: $color-aqua;
    margin-left: 13px;
    width: 15px;
  }

  &--aqua {
    background-color: $color-aqua;

    &:active,
    &:hover {
      background-color: $color-cf-black;
      border-color: $color-white;
      color: $color-white;
    }

    &[disabled] {
      background-color: $color-aqua-light;
      border-color: $color-aqua-light;
      color: $color-grey-shade-1;
    }
  }

  &--simple {
    border: none;
    border-radius: 0;
    color: $color-white;
    padding: 0;
    position: relative;
    text-align: left;
    &::after {
      content: none;
    }
  }

  &--simple-aqua {
    border: none;
    border-radius: 0;
    color: $color-aqua;
    padding: 0;
    position: relative;
    text-align: left;

    &::after {
      content: none;
    }

    &:active,
    &:hover {
      text-decoration: underline;
    }
  }

  &--simple-blue {
    border: none;
    border-radius: 0;
    color: $color-blue;
    padding: 0;
    position: relative;
    text-align: left;

    &::after {
      content: none;
    }

    &:active,
    &:hover {
      text-decoration: underline;
    }
  }

  &--simple-white {
    border: none;
    border-radius: 0;
    color: $color-white;
    padding: 0;
    position: relative;
    text-align: left;

    &::after {
      content: none;
    }

    &:active,
    &:hover {
      text-decoration: underline;
    }
  }
}
