.form {
  &__container {}

  &__item {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__item--2-cols {
    @include media-breakpoint-up(xl) {
      display: flex;
      gap: 32px;
    }
  }

  &__item--2-cols &__item {
    @include media-breakpoint-up(xl) {
      flex-basis: 50%;
      margin-bottom: 0;
    }
  }

  &__item--checkbox {
  }

  &__title {
    @include font-stack-gilroy-light;
    color: $color-dark-blue;
    font-size: 32px;
    line-height: 34px;
    letter-spacing: 0.4px;
    @include media-breakpoint-up(md) {
      line-height: 38px;
    }
  }

  &--light &__title {
    color: $color-white;
  }

  &__description {
    > p {
      @include font-stack-montserrat;
      color: $color-dark-blue;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.2;
      margin-bottom: 20px;
      @include media-breakpoint-up(md) {
        font-size: 16px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &--light &__description {
    > p {
      color: $color-white;
    }
  }

  &__label {
    @include font-stack-montserrat;
    color: $color-dark-blue;
    display: inline-block;
    font-size: 14px;
    font-weight: 800;
    line-height: 20px;
    margin-bottom: 4px;
  }

  &--light &__label {
    color: $color-white;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 1.2;
  }

  &__item--checkbox &__label {
    margin-bottom: 16px;
  }

  &__checkbox-container &__label {
    font-weight: 400;
    margin-bottom: 0;
  }

  &__help {
    > p {
      @include font-stack-montserrat;
      color: $color-dark-blue;
      font-size: 14px;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__required {
    color: $color-red;
  }

  &__control-container {
    position: relative;
  }

  &__checkbox-container {
    display: block;
    margin-bottom: 8px;
  }

  &__checkbox-label {
    align-items: center;
    display: flex;
    gap: 8px;
  }

  &__email,
  &__password,
  &__text,
  &__textarea {
    @include font-stack-montserrat;
    border: 1px solid $color-grey-shade-1;
    color: $color-cf-black;
    display: block;
    font-size: 16px;
    line-height: 20px;
    outline: none;
    padding: 12px;
    width: 100%;

    &::placeholder {
      color: $color-grey-shade-1;
      opacity: 1;
    }
  }

  &--light &__email,
  &--light &__password,
  &--light &__text,
  &--light &__textarea {
    border-radius: 8px;
    padding: 16px 23px;
  }

  &--light &__label {
    border-color: transparent;
  }

  &__item--with-error &__email,
  &__item--with-error &__password,
  &__item--with-error &__text {
    border-color: $color-red;
  }

  &__password-button {
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 50%;
    transform: translate(0, -50%);
  }

  &__password-icon-eye {
    color: $color-grey-shade-1;
  }

  &__password-icon-eye-outline {
    color: $color-grey-shade-1;
  }

  &__info {
    @include font-stack-montserrat;
    align-items: flex-start;
    color: $color-dark-blue;
    display: flex;
    font-size: 12px;
    font-weight: 500;
    line-height: 18px;
    margin-top: 4px;
    @include media-breakpoint-up(xl) {
      align-items: center;
      white-space: nowrap;
    }
  }

  &__info-icon {
    flex-shrink: 0;
    margin-right: 5px;
    margin-top: 4px;
    @include media-breakpoint-up(xl) {
      margin-top: 0;
    }
  }

  &__error {
    @include font-stack-montserrat;
    align-items: center;
    color: $color-red;
    display: flex;
    font-size: 14px;
    line-height: 18px;
    margin-top: 4px;
  }

  &__error-icon {
    margin-right: 5px;
  }

  &__counter {
    @include font-stack-montserrat;
    color: $color-grey-shade-1;
    font-size: 14px;
    line-height: 1.2;
    margin-top: 4px;
    text-align: right;
  }

  &__counter--with-warning {
    color: $color-red;
  }

  &__submit {
    @include media-breakpoint-down(md) {
      width: 100%;
    }
  }
}
