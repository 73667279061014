.podcast-card {
  color: $color-white;

  &__text-container {
    max-width: 599px;
    text-align: center;
    @include media-breakpoint-up(xl) {
      text-align: left;
      margin-right: 45px;
    }
  }

  &__title {
    @include font-stack-gilroy-light;
    font-size: 32px;
    letter-spacing: 0.422px;
    @include media-breakpoint-up(md) {
      font-size: 64px;
    }

    > span {
      color: $color-aqua;
    }
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 24px;
    @include media-breakpoint-up(md) {
      font-size: 24px;
    }
    @include media-breakpoint-xl-small() {
      margin-bottom: 8px;
    }
  }

  &__line {
    display: none;
    @include media-breakpoint-up(xl) {
      display: block;
      width: 100;
    }
  }

  &__text {
    font-size: 14px;
    font-weight: 300;
    margin-bottom: 47px;
    @include media-breakpoint-up(md) {
      font-size: 16px;
    }
    @include media-breakpoint-xl-small() {
      margin-bottom: 20px;
    }
  }

  &__line {
    margin: 47px 0 44px;
    max-width: 880px;
    @include media-breakpoint-xl-small() {
      margin: 20px 0;
    }
  }
}
