.content {
  position: relative;
  @include media-breakpoint-up(xl) {
    width: auto;
  }

  &:before {
    @include blue-gradient-responsive;
    bottom: 0;
    content: '';
    left: 0;
    position: absolute;
    right: 0;
    top: -500px;
    z-index: $zi-below-ground;
    @include media-breakpoint-up(md) {
      top: -350px;
    }
  }

  &--whats-next:before {
    background: $color-cf-black;
  }

  &--article {
    &::after {
      @include media-breakpoint-up(xl) {
        content: '';
        position: absolute;
        top: 0;
        left: -76px;
        width: 796px;
        height: 645px;
        background: radial-gradient(
            41.95% 63.05% at 39.7% 71.24%,
            rgba(103, 237, 255, 0.2) 0%,
            rgba(103, 246, 255, 0) 100%
        );
        transform: rotate(90deg);
        filter: blur(30px);
      }
    }
  }

  &--home {
    @include media-breakpoint-up(xl) {
      // TODO
      width: 11480px;
      // END TODO
    }

    &:before {
      background: linear-gradient(360deg, $color-cf-black 50%, $color-special-blue 100%);
      @include media-breakpoint-up(xl) {
        @include blue-gradient-horizontal;
      }
    }

    &:after {
      @include media-breakpoint-up(xl) {
        background: $color-cf-black;
        bottom: 0;
        content: '';
        position: absolute;
        right: 0;
        top: 0;
        width: 5865px;
        z-index: $zi-below-ground;
      }
    }
  }

  > .container {
    @include media-breakpoint-up(xl) {
      max-width: none;
      padding: 0;
    }
  }

  > .container > .row {
    @include media-breakpoint-up(xl) {
      margin: 0;
    }
  }

  > .container > .row > .col {
    @include media-breakpoint-up(xl) {
      padding: 0;
    }
  }

  &__container {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: 30px 0;
    position: relative;
    @include media-breakpoint-up(md) {
      gap: 120px;
    }
    @include media-breakpoint-up(xl) {
      align-items: center;
      flex-direction: row;
      gap: 84px;
      height: calc(var(--vh, 1vh) * 100);
      padding-left: calc(450px + 205px + 120px);
      padding-right: 100px;
    }
  }

  &--article &__container {
    padding-left: 0;
    padding-right: 0;
    @include media-breakpoint-up(md) {
      gap: 60px;
    }
    @include media-breakpoint-up(xl) {
      gap: 84px;
    }
    @media (min-width: 1400px) and (max-height: 900px) {
      height: auto;
    }
  }

  &--home &__container {
    > .pull-quote--home {
      @include media-breakpoint-up(xl) {
        flex-basis: 1300px;
        margin-right: 260px;
      }

      .pull-quote__text {
        @include media-breakpoint-up(xl) {
          width: 580px;
        }
      }
    }

    > .content-card--next {
      @include media-breakpoint-up(xl) {
        height: calc(var(--vh, 1vh) * 100);
        padding-right: 570px;
      }

      .content-card__title {
        > span {
          display: inline;
          @include media-breakpoint-up(xl) {
            display: block;
          }
        }
      }

      .content-card__media {
        @include media-breakpoint-up(xl) {
          bottom: 0;
          height: calc((var(--vh, 1vh) * 100) - 100px);
          left: 276px;
          position: absolute;
          top: auto;
          width: 678px;
        }
      }

      .content-card__image {
        @include media-breakpoint-up(xl) {
          height: 100%;
          max-width: none;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }

    > .content-card--broadening {
      @include media-breakpoint-up(xl) {
        flex-basis: 360px;
      }
    }

    > .content-card--investing {
      @include media-breakpoint-up(xl) {
        flex-basis: 456px;
        flex-shrink: 0;
        margin-right: 120px;
      }

      .content-card__title {
        > span {
          display: inline;
        }
      }

      .content-card__cta-line {
        @include media-breakpoint-up(xl) {
          left: -720px;
          width: 680px;
          z-index: $zi-below-ground;
        }
      }
    }

    > .content-card--work {
      @include media-breakpoint-up(xl) {
        flex-basis: 534px;
      }
    }

    > .map {
      @include media-breakpoint-up(xl) {
        flex-basis: 1280px;
      }
    }
  }

  &--whats-next &__container {
    @include media-breakpoint-up(xl) {
      gap: 0;
      padding-left: 400px;
    }

    > .content-card--coalition {
      position: static;
      @include media-breakpoint-up(xl) {
        flex-basis: 578px;
        margin-right: 198px;
      }

      .content-card__header {
        order: 1;
      }

      .content-card__description {
        order: 2;
        @include media-breakpoint-up(xl) {
          align-self: flex-start;
          max-width: 578px;
        }
      }

      .content-card__media {
        order: 0;
        position: absolute;
        top: -424px;
        width: 100%;
        @include media-breakpoint-up(md) {
          top: -678px;
          width: 100%;
        }
        @include media-breakpoint-up(xl) {
          bottom: 0;
          height: calc((var(--vh, 1vh) * 100) - 100px);
          left: -339px;
          top: auto;
          width: 678px;
        }
      }

      .content-card__image {
        height: 424px;
        max-width: none;
        width: auto;
        @include media-breakpoint-up(md) {
          height: 678px;
        }
        @include media-breakpoint-up(xl) {
          height: 100%;
          object-fit: cover;
          object-position: 50% 50%;
        }
      }
    }

    > .content-card--ground {
      @include media-breakpoint-up(xl) {
        margin-right: 266px;
        width: 686px;
      }

      .content-card__line {
        @include media-breakpoint-up(xl) {
          bottom: -34px;
          left: 100%;
          width: 240px;
        }
      }
    }
  }
}
