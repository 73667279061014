.pull-quote {
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  @include media-breakpoint-up(md) {
    overflow: visible;
  }
  @include media-breakpoint-up(xl) {
    flex-direction: row;
  }

  &__line {
    margin-bottom: 60px;

    @include media-breakpoint-up(md) {
      margin-bottom: 64px;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 120px 0 0;
      transform: rotate(-90deg);
    }

    .line__container {
      height: 78px;
      transform: rotate(90deg);
      width: 78px;

      @include media-breakpoint-up(md) {
        height: 155px;
        width: 155px;
      }

      @include media-breakpoint-up(xl) {
        height: 302px;
        width: 302px;
      }
    }
  }

  &--without-horizontal-line {
    @include media-breakpoint-up(xl) {
      margin-right: -80px;
    }
    .pull-quote__line {
      @include media-breakpoint-up(xl) {
        margin-left: -40px;
      }

      .line__container {
        &::before,
        &::after {
          @include media-breakpoint-up(xl) {
            content: none;
          }
        }
      }
    }
  }

  &--without-arrow {
    .pull-quote__line {
      .line__container {
        &::before {
          @include media-breakpoint-up(xl) {
            content: none;
          }
        }
      }
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(xl) {
      align-items: flex-start;
      flex-basis: 700px;
      flex-direction: row;
    }
  }

  &__quote {
    fill: $color-aqua;
    @include media-breakpoint-up(md) {
      width: 73px;
    }

    @include media-breakpoint-up(xl) {
      width: 95px;
      margin-top: 16px;
    }
  }

  &__body {
    margin-top: 12px;

    @include media-breakpoint-up(md) {
      margin-top: 40px;
    }

    @include media-breakpoint-up(xl) {
      margin: 0 0 0 40px;
    }
  }

  &__text {
    @include font-stack-gilroy-medium;
    color: $color-white;
    font-size: 16px;
    letter-spacing: 0.42px;
    max-width: 475px;
    text-align: center;
    width: 100%;

    @include media-breakpoint-up(md) {
      font-size: 32px;
      text-align: left;
    }
    @include media-breakpoint-up(xl) {
      line-height: 1.2;
      max-width: 745px;
    }
    @include media-breakpoint-xl-small() {
      font-size: 28px;
      line-height: 1.2;
    }
  }

  &__description {
    color: $color-white;
    margin: 16px 0 40px 0;
    max-width: 473px;
    text-align: center;

    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
  }

  &__author {
    @include font-stack-montserrat;
    color: $color-aqua;
    display: flex;
    font-size: 14px;
    justify-content: center;
    margin-top: 24px;

    @include media-breakpoint-up(md) {
      font-size: 16px;
      margin-top: 40px;
    }

    @include media-breakpoint-up(xl) {
      justify-content: flex-start;
      margin-top: 17px;
    }
    &-name {
      font-weight: bold;
      white-space: nowrap;
    }

    &-organization {
      font-weight: 300;

      &::before {
        content: '-';
        margin: 0 6px;
      }
    }
  }

  &--schools, &--leadership {
    .pull-quote__icon {
      display: none;
    }
    .pull-quote__text {
      max-width: 206px;
      font-size: 32px;
      line-height: normal;
      @include font-stack-gilroy-light;
      @include media-breakpoint-up(sm) {
        font-size: 64px;
        max-width: 476px;
      }
      @include media-breakpoint-up(xl) {
        width: 507px;
        max-width: none;
      }
      & > span {
        color: $color-aqua;
      }
    }

    .pull-quote__text, .pull-quote__description {
      text-align: center;

      @include media-breakpoint-up(xl) {
        text-align: left;
      }
    }
  }

  &--leadership {
    @include media-breakpoint-up(xl) {
      margin-left: 128px;
    }
    .pull-quote__body {
      display: flex;
      flex-direction: column;
      align-items: center;
      max-width: 215px;

      @include media-breakpoint-up(sm) {
        max-width: 476px;

        .pull-quote__description {
          max-width: 306px;
        }
      }

      @include media-breakpoint-up(xl) {
        align-items: flex-start;
        margin-right: 70px;

        .pull-quote__text {
          font-size: 96px;
          width: 515px;
          line-height: normal;
        }

        .pull-quote__description {
          max-width: 480px;
        }
      }
    }
  }

  &--reverse {
    .line {
      margin: 60px 0 0 0;
      order: 1;
      rotate: 180deg;

      @include media-breakpoint-up(xl) {
        margin-top: 0;
      }
    }
  }
}
