.m {
  &--alert &__icon {
    align-items: center;
    background: $color-light-green;
    border-radius: 50%;
    display: flex;
    height: 48px;
    justify-content: center;
    margin: 0 auto 16px auto;
    width: 48px;
    @include media-breakpoint-up(md) {
      height: 66px;
      width: 66px;
    }
    @include media-breakpoint-up(xl) {
      height: 100px;
      width: 100px;
    }
  }

  &--alert &__icon-check {
    width: 24px;
    @include media-breakpoint-up(md) {
      width: 32px;
    }
    @include media-breakpoint-up(xl) {
      width: 50px;
    }
  }

  &--alert &__description {
    align-items: center;
    display: flex;
    flex-direction: column;

    > h2 {
      @include font-stack-gilroy-light;
      color: $color-dark-blue;
      font-size: 32px;
      line-height: 1.2;
      letter-spacing: 0.2px;
      margin-bottom: 12px;
      text-align: center;
      @include media-breakpoint-up(md) {
        margin-bottom: 70px;
      }
      @include media-breakpoint-up(xl) {
        font-size: 64px;
        margin-bottom: 40px;
      }
    }

    > p {
      @include font-stack-montserrat;
      color: $color-dark-blue;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.4;
      text-align: center;
      margin-bottom: 30px;
      @include media-breakpoint-up(md) {
        max-width: 530px;
      }
      @include media-breakpoint-up(xl) {
        max-width: 620px;
      }

      &:first-of-type {
        @include media-breakpoint-up(md) {
          font-size: 16px;
        }
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
