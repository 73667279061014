$zi-highest: 50;
$zi-high: 40;
$zi-medium: 30;
$zi-low: 20;
$zi-lowest: 10;
$zi-ground: 0;
$zi-below-ground: -1;

$color-black: #000;
$color-white: #fff;
$color-navy: #186999;
$color-navy-2: #004e89;
$color-special-blue: #3191b7;
$color-blue: #3ca3c7;
$color-blue-2: #1593bf;
$color-light-blue: #ccf0fe;
$color-aqua: #67ffba;
$color-aqua-light: #b2efd3;
$color-cf-black: #122947;
$color-grey: #d6d6d6;
$color-grey-2: #4c5761;
$color-grey-3: #c8c8c8;
$color-grey-shade-1: #86949f;
$color-grey-shade-2: #8792a1;
$color-green: #99b754;
$color-brown: #736455;
$color-dark-blue: #2e4057;
$color-darkest-blue: #132a48;
$color-yellow: #f4d35e;
$color-light-yellow: #f4d35e;
$color-orange: #ff6542;
$color-red: #e84545;
$color-light-green: #83e8ba;
$color-purple: #b95f89;
$color-red: #e84545;
$color-medium-black: #132338;
$color-lime-yellow: #7d983f;
