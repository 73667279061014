.form {
  &--password &__item--title {
    margin-bottom: 16px;
    @include media-breakpoint-up(md) {
      margin-bottom: 12px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 6px;
    }
  }

  &--password &__item--description {
    margin-bottom: 32px;
    line-height: 24px;
  }

  &--password &__item--password {
    margin-bottom: 28px;
  }

  &--password &__title {
    @include font-stack-gilroy-light;
    color: unset;
    line-height: 42px;
    margin-bottom: 16px;
    font-size: 32px;
    @include media-breakpoint-up(md) {
      font-size: 40px;
      max-width: 260px;
    }
  }

  &--password &__label {
    margin-bottom: 4px;
    color: unset;
    font-weight: 800;
    font-size: 14px;
  }

  &--password &__text {
    padding-right: 12px;
    border-radius: 0;
  }
}
