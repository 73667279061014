.header {
  left: 0;
  padding: 12px 0;
  position: sticky;
  right: 0;
  top: -1px;
  z-index: $zi-low;
  @include media-breakpoint-up(md) {
    padding: 24px 0;
  }

  &--open {
    background: $color-darkest-blue;
    height: calc(var(--vh, 1vh) * 100);
    inset: 0;
    overflow-x: hidden;
    overflow-y: auto;
    position: fixed;
    z-index: $zi-low;
  }

  &--stuck {
    @include media-breakpoint-up(md) {
      padding: 20px 0;
    }
  }

  &__container {
    position: relative;
  }

  &__belt {
    align-items: center;
    display: flex;
    justify-content: flex-end;
  }

  &--open &__belt {
    justify-content: space-between;
    margin-bottom: 54px;
    @include media-breakpoint-up(md) {
      margin-bottom: 84px;
    }
    @include media-breakpoint-up(xl) {
      margin-bottom: 54px;
    }
  }

  &__branding {
  }

  &__branding-link {
  }

  &__logo {
    color: $color-white;
    width: 128px;
    @include media-breakpoint-up(md) {
      width: 200px;
    }
  }

  &__menu {
  }

  &__menu-button {
    align-items: center;
    background: $color-medium-black;
    border-radius: 50%;
    display: inline-block;
    border: none;
    height: 40px;
    margin: 0;
    padding: 0;
    width: 40px;
    vertical-align: middle;
    @include media-breakpoint-up(md) {
      height: 58px;
      width: 58px;
    }

    &-bar {
      height: 2px;
      display: block;
      width: 20px;
      margin: 5px auto;
      position: relative;
      background-color: #fff;
      border-radius: 10px;
      @include transition(0.4s);
      &:nth-of-type(1) {
        animation: rotateR 1.5s cubic-bezier(0.5, 0.2, 0.2, 1);
      }
      &:nth-of-type(2) {
        animation: fade 1.5s cubic-bezier(0.1, 0.8, 0.1, 1);
      }
      &:nth-of-type(3) {
        animation: rotateL 1.5s cubic-bezier(0.5, 0.2, 0.2, 1);
      }
      @include media-breakpoint-up(md) {
        width: 30px;
        margin: 7px auto;
      }
    }
  }

  &--open &__menu-button-bar,
  &__menu-button--close &__menu-button-bar {
    &:nth-of-type(1) {
      transform: translateY(7px) rotate(45deg);
    }
    &:nth-of-type(2) {
      opacity: 0;
    }
    &:nth-of-type(3) {
      transform: translateY(-7px) rotate(-45deg);
    }
    @include media-breakpoint-up(md) {
      &:nth-of-type(1) {
        transform: translateY(9px) rotate(45deg);
      }
      &:nth-of-type(3) {
        transform: translateY(-9px) rotate(-45deg);
      }
    }
  }

  &__menu-button--close {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__menu-burger {
    color: $color-white;
    @include media-breakpoint-up(md) {
      width: 30px;
    }
  }

  &__menu-close {
    color: $color-white;
    @include media-breakpoint-up(md) {
      width: 16px;
    }
    @include media-breakpoint-up(xl) {
      width: 22px;
    }
  }

  &__body {
    display: none;
  }

  &--open &__body {
    display: flex;
    flex-direction: column;
    position: relative;
    @include media-breakpoint-up(xl) {
      min-height: calc((var(--vh, 1vh) * 100) - 24px - 58px - 54px - 24px);
      justify-content: flex-end;
    }
  }

  &__nav {
    margin-bottom: 100px;
    @include media-breakpoint-up(xl) {
      margin-bottom: auto;
      max-width: 40%;
    }
  }

  &__nav-list {
    display: flex;
    flex-direction: column;
    gap: 24px;
    list-style: none;
    margin: 0;
    padding: 0;
    @include media-breakpoint-up(md) {
      gap: 48px;
    }
    @include media-breakpoint-up(xl) {
      gap: 34px;
    }
  }

  &__nav-list-item {
  }

  &__nav-list-item-link {
    @include font-stack-gilroy-light;
    color: $color-white;
    cursor: pointer;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: 0.422px;
    @include media-breakpoint-up(md) {
      font-size: 32px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 48px;
    }
    @include media-breakpoint-xl-small() {
      font-size: 32px;
    }

    &:hover {
      color: $color-aqua;
    }
  }

  &__nav-list-item-link-active {
  }

  &__lessons-nav {
    border-left: 1px solid $color-white;
    margin-bottom: 24px;
    padding-bottom: 64px;
    padding-left: 12px;
    position: relative;
    @include media-breakpoint-up(md) {
      padding-left: 54px;
    }
    @include media-breakpoint-up(xl) {
      bottom: 0;
      margin-bottom: 0;
      padding-bottom: 40px;
      padding-left: 40px;
      position: absolute;
      right: 0;
      top: 0;
      width: 60%;
    }

    &:before {
      border-style: solid;
      border-width: 7px 3px 0 3px;
      border-color: $color-white transparent transparent transparent;
      content: '';
      height: 0;
      left: -3.5px;
      position: absolute;
      top: 0;
      width: 0;
    }

    &:after {
      background: $color-white;
      bottom: 0;
      content: '';
      height: 1px;
      left: 50%;
      margin: 0 -50vw;
      position: absolute;
      right: 50%;
      width: 100vw;
      @include media-breakpoint-up(xl) {
        content: normal;
      }
    }

    .lessons-nav__body {
      @include media-breakpoint-up(xl) {
        height: calc((var(--vh, 1vh) * 100) - 24px - 58px - 54px - 58px - 40px - 24px);
        overflow-y: auto;
      }
    }
  }

  &__contact {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    @include media-breakpoint-up(xl) {
      align-items: flex-start;
      margin-bottom: 30px;
      max-width: 40%;
    }
  }

  &__contact-title {
    @include font-stack-montserrat;
    color: $color-white;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0.422px;
    line-height: 1.2;
  }

  &__contact-email {
  }

  &__contact-email-link {
    @include font-stack-montserrat;
    color: $color-white;
    font-size: 16px;
    letter-spacing: 0.422px;
    line-height: 1.2;

    &:hover {
      color: $color-aqua;
    }
  }

  &__social-nav {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;
    @include media-breakpoint-up(xl) {
      justify-content: flex-start;
      margin-bottom: 24px;
      max-width: 40%;
    }
  }

  &__copy {
    @include font-stack-montserrat;
    color: $color-white;
    font-size: 12px;
    font-style: italic;
    line-height: 1.2;
    text-align: center;
    @include media-breakpoint-up(xl) {
      max-width: 40%;
      text-align: left;
    }
  }
}
