.menu-open {
  overflow: hidden;
}

main {
  margin-top: -64px;
  @include media-breakpoint-up(md) {
    margin-top: -106px;
  }
  @include media-breakpoint-up(xl) {
    display: flex;
    overflow-y: hidden;
  }

  > div {
    flex-shrink: 0;
    width: 100vw;
  }
}

