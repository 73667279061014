.attendee {
  @include media-breakpoint-up(lg) {
    padding: 0 50px;
  }
  @include media-breakpoint-up(xl) {
    padding: 0 80px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(146px, 1fr));
    column-gap: 40px;
    row-gap: 24px;
    @include media-breakpoint-up(lg) {
      column-gap: 140px;
    }
    @include media-breakpoint-up(xl) {
      column-gap: 20px;
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }

  &__title {
    @include font-stack-gilroy-light;
    color: $color-dark-blue;
    font-size: 32px;
    line-height: 1.2;
    letter-spacing: 0.422px;
    margin-bottom: 38px;
    @include media-breakpoint-up(xl) {
      font-size: 64px;
      margin-bottom: 64px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding-bottom: 8px;
    position: relative;

    &::after {
      content: '';
      background-color: $color-grey-3;
      width: 28px;
      height: 1px;
      position: absolute;
      bottom: 0;
      left: 0;
    }

    &-name {
      color: $color-navy-2;
      font-weight: 700;
      line-height: 1.1;
      letter-spacing: 0.3px;
    }

    &-institution {
      color: $color-grey-2;
      font-size: 12px;
      line-height: 1;
      letter-spacing: -0.2px;
      font-style: italic;
    }

    &-email {
      color: $color-blue-2;
      font-size: 12px;
      line-height: 1.1;
      letter-spacing: 0.8px;
      font-weight: 500;
      word-break: break-all;
    }
  }
}
