.icon-arrow-up-right { width: 9px; }
.icon-calendar { width: 16px; }
.icon-ceo { width: 80px; }
.icon-close { width: 10px; }
.icon-couple { width: 80px; }
.icon-eye { width: 22px; }
.icon-eye-outline { width: 22px; }
.icon-facebook { width: 10px; }
.icon-geo { width: 16px; }
.icon-instagram { width: 19px; }
.icon-linkedin { width: 25px; }
.icon-logo { width: 364px; }
.icon-mail { width: 24px; }
.icon-quote { width: 41px; }
.icon-school { width: 118px; }
.icon-stats-analysis {
  width: 80px;
  .icon-fill-1 { fill: $color-yellow; }
}
.icon-twitter { width: 20px; }
.icon-warning { width: 10px; }
.icon-warning-filled {
  width: 18px;
  .icon-fill-1 { fill: $color-white; }
  .icon-fill-2 { fill: $color-red; }
}
.icon-check { width: 54px; }
.icon-spinner {
  animation: rotate 1.8s linear infinite;
  width: 20px;

  > circle {
    animation: spin 1.2s ease-in-out infinite
  }
}
.icon-arrow-bottom-right {
  width: 73px;
}
