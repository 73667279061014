.content-card {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  @include media-breakpoint-up(md) {
    max-width: 475px;
  }
  @include media-breakpoint-up(xl) {
    max-width: none;
  }

  &--next {
    @include media-breakpoint-up(xl) {
      flex-direction: row;
    }
  }

  &__header {
    margin-bottom: 16px;
    max-width: 236px;
    position: relative;
    @include media-breakpoint-up(md) {
      max-width: none;
      width: 100%;
    }
  }

  &--with-line &__header {
    @include media-breakpoint-up(xl) {
      padding-bottom: 18px;
      padding-right: 76px;
    }
  }

  &--next &__header {
    @include media-breakpoint-up(xl) {
      margin-bottom: 0;
    }
  }

  &__title {
    @include title;
    @include media-breakpoint-up(xl) {
      text-align: left;
    }

    > span {
      display: block;
      white-space: nowrap;
    }
  }

  &--large &__title {
    @include media-breakpoint-up(xl) {
      font-size: 96px;
      line-height: 1;
    }
  }

  &__line {
    display: none;
    @include media-breakpoint-up(xl) {
      bottom: 0;
      display: block;
      left: 0;
      position: absolute;
      right: 0;
    }
  }

  &_subtitle {}

  &__description {
    > p {
      @include font-stack-montserrat;
      color: $color-white;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.4;
      margin-bottom: 20px;
      text-align: center;
      @include media-breakpoint-up(md) {
        font-size: 16px;
        text-align: left;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__cta {
    align-self: flex-start;
    margin-top: 24px;
    position: relative;
    width: 100%;
    @include media-breakpoint-up(md) {
      margin-top: 48px;
    }
    @include media-breakpoint-up(md) {
      margin-top: 64px;
    }
  }

  &__cta-button {
    display: block;
  }

  &__cta-line {
    display: none;
    @include media-breakpoint-up(xl) {
      display: block;
      left: -520px;
      position: absolute;
      top: 50%;
      transform: translate(0, -50%);
      width: 480px;
    }
  }

  &__media {
    display: flex;
    justify-content: center;
  }

  &__image {
    display: block;
    max-width: 100%;
    @include media-breakpoint-up(md) {
      max-width: 475px;
    }
  }
}
