.m {
  &--open {
    overflow: hidden;
  }

  &__overlay {
    background: rgba($color-dark-blue, 0.6);
    inset: 0;
    position: fixed;
    z-index: $zi-highest;
  }

  &__content {
    background: $color-white;
    inset: 0;
    overflow: auto;
    outline: none;
    position: absolute;
  }

  &--extra-small &__content {
    @include media-breakpoint-up(md) {
      box-shadow: 0 4px 8px 0 rgba($color-black, 0.4);
      border-radius: 16px;
      bottom: auto;
      left: 50%;
      min-height: 560px;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 640px;
    }

    @include media-breakpoint-up(xl) {
      width: 830px;
    }
  }

  &--small &__content {
    @include media-breakpoint-up(md) {
      box-shadow: 0 4px 8px 0 rgba($color-black, 0.4);
      border-radius: 16px;
      bottom: 56px;
      left: 50%;
      top: 56px;
      transform: translate(-50%, 0);
      width: calc(100% - 80px);
    }

    @include media-breakpoint-up(xl) {
      bottom: 20px;
      top: 20px;
      width: 1146px;
    }
  }

  &--with-gradients &__content {
    background: linear-gradient(140.96deg, #ffffff 50.02%, #6fd3fa 204.23%);

    &:before {
      background: radial-gradient(
        58.25% 67.3% at -3.2% 42.19%,
        rgba(103, 237, 255, 0.2) 0%,
        rgba(103, 246, 255, 0) 100%
      );
      content: '';
      filter: blur(30px);
      height: 200px;
      position: fixed;
      top: 0;
      width: 100%;
      z-index: $zi-ground;
    }

    &:after {
      background: radial-gradient(
        136.69% 78.13% at -4.25% 0%,
        rgba(103, 255, 186, 0.3) 0%,
        rgba(103, 255, 186, 0) 100%
      );
      filter: blur(30px);
      transform: matrix(1, 0, 0, -1, 0, 0);
      bottom: 24px;
      content: '';
      height: 544px;
      left: 0%;
      position: fixed;
      width: 212px;
      z-index: $zi-ground;
    }
  }

  &__header {
    margin-bottom: 24px;
    position: relative;
    z-index: $zi-ground;
  }

  &--extra-small &__header {
    margin-bottom: 0;
  }

  &--small &__header {
    border-bottom: 1px solid $color-grey-shade-1;
  }

  &__header-container {
    align-items: center;
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
    @include media-breakpoint-up(md) {
      padding: 24px 0;
    }
  }

  &--with-logo &__header-container {
    justify-content: space-between;
  }

  &--with-logo-line &__header .container {
    border-bottom: 1px solid $color-grey;
  }

  &--with-logo-line &__header-container {
    @include media-breakpoint-up(lg) {
      padding-bottom: 42px;
    }
  }

  &--extra-small &__header-container {
    padding-bottom: 0;
  }

  &--small &__header-container {
    @include media-breakpoint-up(md) {
      margin: 0 auto;
      max-width: 600px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 786px;
    }
  }

  &__branding {
  }

  &__branding-link {
  }

  &__logo {
    color: $color-blue;
    width: 91px;
    @include media-breakpoint-up(md) {
      width: 182px;
    }
    @include media-breakpoint-up(xl) {
      width: 200px;
    }
  }

  &--small &__logo {
    @include media-breakpoint-up(xl) {
      width: 182px;
    }
  }

  &__close {
    align-items: center;
    background: $color-dark-blue;
    border-radius: 50%;
    display: flex;
    height: 24px;
    justify-content: center;
    min-width: 0;
    padding: 0;
    width: 24px;
    @include media-breakpoint-up(md) {
      height: 40px;
      width: 40px;
    }
    @include media-breakpoint-up(xl) {
      height: 58px;
      width: 58px;
    }
  }

  &__close-icon {
    color: $color-white;
    @include media-breakpoint-up(md) {
      width: 16px;
    }
    @include media-breakpoint-up(xl) {
      width: 22px;
    }
  }

  &__body {
    padding-bottom: 40px;
    position: relative;
    z-index: $zi-ground;
  }

  &__body-container {
  }

  &--small &__body-container {
    @include media-breakpoint-up(md) {
      margin: 0 auto;
      max-width: 600px;
    }
    @include media-breakpoint-up(xl) {
      max-width: 786px;
    }
  }

  &--auto-height &__content {
    @include media-breakpoint-up(md) {
      bottom: auto;
      padding-bottom: 50px;
    }
  }
}
