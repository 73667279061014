@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/gilroy/GilroyLight/font.woff2') format('woff2'), url('../../fonts/gilroy/GilroyLight/font.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 300;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/gilroy/GilroyMedium/font.woff2') format('woff2'), url('../../fonts/gilroy/GilroyMedium/font.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 500;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/gilroy/GilroySemiBold/font.woff2') format('woff2'), url('../../fonts/gilroy/GilroySemiBold/font.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 600;
}

@font-face {
  font-family: 'Gilroy';
  src: url('../../fonts/gilroy/GilroyBold/font.woff2') format('woff2'), url('../../fonts/gilroy/GilroyBold/font.woff') format('woff');
  font-display: swap;
  font-style: normal;
  font-weight: 700;
}

