.article-map {
  display: flex;
  flex-direction: column;
  gap: 80px;
  align-self: flex-start;
  max-width: 1000px;
  align-items: center;
  margin-top: -30px;
  @include media-breakpoint-up(xl) {
    gap: 10px;
    margin-top: 40px;
  }

  &__container {
    width: 100%;
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
    @include media-breakpoint-up(xl) {
      width: 960px;
      height: auto;
    }
    @include media-breakpoint-xl-small() {
      height: auto;
      width: 680px;
    }
  }

  &__image {
    max-width: 100%;
  }

  &__card {
    max-width: 1000px;
  }

  .lesson-card__description {
    > p {
      font-size: 14px;
    }
  }

  .lesson-card__content {
    @include media-breakpoint-up(md) {
      gap: 4px;
    }
  }
}
