.article-hero {
  background: linear-gradient(164deg, $color-cf-black 0%, $color-special-blue 100%);
  position: relative;
  color: $color-white;
  overflow: hidden;
  @include media-breakpoint-up(xl) {
    padding-bottom: 0;
  }

  &--whats-next {
    @include media-breakpoint-up(xxl) {
      width: 80vw;
    }
    @include media-breakpoint-xl-small() {
      align-items: center;
      display: flex;
    }

    .article-hero__title {
      @include media-breakpoint-up(xl) {
        width: 500px;
      }
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: -102px;
    right: 0;
    filter: blur(30px);
    width: 264px;
    height: 468px;
    transform: rotate(90deg);
    background: radial-gradient(
      43.61% 66.89% at 13.73% 52.19%,
      rgba(103, 237, 255, 0.15) 0%,
      rgba(103, 246, 255, 0) 100%
    );
    @include media-breakpoint-up(md) {
      left: 0;
      top: 0;
      right: auto;
      height: 770px;
      width: 418px;
      transform: none;
      background: radial-gradient(
        79.09% 129.06% at -6.55% -8.85%,
        rgba(103, 237, 255, 0.3) 0%,
        rgba(103, 246, 255, 0) 100%
      );
    }
  }

  &__container {
    display: flex;
    flex-direction: column;
    padding-top: 64px;
    padding-bottom: 30px;
    position: relative;
    @include media-breakpoint-up(xl) {
      height: calc(var(--vh, 1vh) * 100);
      padding-bottom: 14px;
    }
    @media (min-width: 1400px) and (max-height: 900px) {
      height: auto;
    }
  }

  &--vertical-centered &__container {
    @include media-breakpoint-up(xl) {
      justify-content: center;
      padding: 0 14px;
    }
  }

  &--whats-next &__container {
    padding-bottom: 480px;
    @include media-breakpoint-up(md) {
      padding-bottom: 762px;
    }
    @include media-breakpoint-up(xl) {
      padding-left: 80px;
      padding-bottom: 14px;
    }
    @include media-breakpoint-up (xxl) {
      padding-left: 128px;
    }
  }

  &--whats-next &__title {
    @include media-breakpoint-up(xl) {
      width: 500px;
    }
  }

  &__content {
    flex-shrink: 0;
  }

  &__content > *:not(.line) {
    @include media-breakpoint-up(md) {
      padding-right: 150px;
    }
  }

  &--large &__content > *:not(.line) {
    @include media-breakpoint-up(xl) {
      padding-right: 0;
    }
  }

  &__line {
    display: none;
    @include media-breakpoint-up(xl) {
      display: block;
    }
  }

  &--whats-next &__line {
    @include media-breakpoint-up(xl) {
      width: 100vw;
    }
  }

  &__index {
    @include font-stack-gilroy-light;
    color: $color-blue;
    font-size: 32px;
    letter-spacing: 0.422px;
    @include media-breakpoint-up(sm) {
      font-size: 84px;
    }
    @include media-breakpoint-xl-small() {
      font-size: 64px;
    }
  }

  &__logo {
    margin: 14px 0;
    @include media-breakpoint-up(md) {
      margin: 18px 0 36px;
    }
    @include media-breakpoint-xl-small() {
      margin: 12px 0;
    }
  }

  &__icon {
    width: 109px;
    @include media-breakpoint-up(md) {
      width: 301px;
    }
    @include media-breakpoint-xl-small() {
      width: 220px;
    }
  }

  &__title {
    @include font-stack-gilroy-light;
    font-size: 32px;
    letter-spacing: 0.422px;
    margin-bottom: 16px;
    line-height: 1.2;
    @include media-breakpoint-up(md) {
      font-size: 72px;
      max-width: 779px;
      margin-bottom: 32px;
    }
    @include media-breakpoint-up(xl) {
      font-size: 64px;
      line-height: 1.1;
    }
    @include media-breakpoint-xl-small() {
      font-size: 56px;
    }
  }

  &--large &__title {
  }

  &__description {
    max-width: 774px;
    font-size: 14px;
    @include media-breakpoint-up(md) {
      font-size: 22px;
      margin-top: 16px;
    }
    @include media-breakpoint-up(xl) {
      line-height: 1.2;
    }
    @include media-breakpoint-xl-small() {
      font-size: 18px;
      line-height: 1.2;
    }
  }

  &--large &__description {
  }

  &__spacer {
    // 11% is the padding from the video.
    // %2 Because is half of video
    // 20px space between text and video
    width: calc(((100% - 11%) / 2) + 20px);
    aspect-ratio: 16 / 9;
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &--whats-next &__spacer {
    display: none;
  }

  &__video .video-card {
    background: gray;
    width: 1239px;
    height: 654px;
  }
}
